<template>
  <div
    v-click-outside="onClickOutside"
    class="relative inline-block"
    v-bind="$attrs"
  >
    <slot
      name="control"
      :toggle-dropdown="toggleDropdown"
    />
    <transition name="slide">
      <component
        :is="as"
        v-if="localIsVisible"
        ref="content"
        :style="contentStyles"
        class="absolute z-20 overflow-y-auto"
        :class="{
          'bg-white dark:bg-black border-gray-300 dark:border-gray-800 rounded shadow border border-solid border-gray-300': !transparent,
          [dropdownPosition]: true,
          'w-full': isFullWidth,
        }"
        data-test="content"
        @click="onContentClick"
      >
        <slot :toggle-dropdown="toggleDropdown" />
      </component>
    </transition>
  </div>
</template>

<script>
import { directive } from 'v-click-outside'

export default {
  name: 'BDropdown',
  directives: {
    'click-outside': directive,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    closeOnContentClick: {
      type: Boolean,
      default: true,
    },
    as: {
      type: String,
      default: 'div',
    },
    height: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    dropdownPosition: {
      type: String,
      default: 'down-left',
      validator: value => ['down-right', 'up-right', 'down-left', 'up-left'].includes(value),
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localIsVisible: false,
  }),
  computed: {
    contentStyles() {
      const styles = {
        'max-height': 'auto',
      }

      if (this.height !== 0) {
        styles['max-height'] = `${this.height}px`
      }

      if (this.width !== 0) {
        styles['max-width'] = `${this.width}px`
      }

      return styles
    },
  },
  watch: {
    isVisible(val) {
      this.localIsVisible = val
    },
    localIsVisible(val) {
      if (val) {
        this.$emit('open')
        return
      }

      this.$emit('close')
    },
  },
  mounted() {
    this.localIsVisible = this.isVisible
  },
  methods: {
    onContentClick() {
      if (!this.closeOnContentClick) return

      this.localIsVisible = false
    },
    toggleDropdown() {
      this.localIsVisible = !this.localIsVisible

      // if (this.localIsVisible) {
      //   this.$emit('open')
      //   return
      // }

      // this.$emit('close')
    },
    onClickOutside() {
      this.localIsVisible = false
    },
  },
}
</script>

<style scoped>
  .slide-enter-active {
    animation: slide 0.2s;
  }

  .slide-leave-to {
    animation: slide 0.15s reverse;
  }

  @keyframes slide {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .down-right {
    @apply top left-0;
  }

  .up-left {
    @apply bottom right-0;
  }

  .up-right {
    @apply bottom left-0;
  }

  .down-left {
    @apply top right-0;
  }

  body[dir=rtl] {
    .down-right {
      left: unset;

      @apply right-0;
    }

    .up-left {
      right: unset;

      @apply left-0;
    }

    .up-right {
      left: unset;

      @apply right-0;
    }

    .down-left {
      right: unset;

      @apply left-0;
    }
  }

  .top {
    top: calc(100% + 4px);
  }

  .bottom {
    bottom: calc(100% + 4px);
  }
</style>
